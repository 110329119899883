import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const SecondPage = () => (
    <Layout>
        <SEO title="Quem Somos"/>
        <h1><strong>Sobre a empresa – Distribuidora de Gelo</strong></h1>


        <p>A Distribuidora de Gelo é uma empresa que atua no fornecimento de gelo na cidade de São Paulo desde 2015
            prezando pela entrega de gelo de alta qualidade e com comodidade e praticidade aos clientes de regiões
            nobres do município.</p>


        <p>Localizada na Vila Mariana, a Distribuidora de Gelo garante a entrega rápida e eficiente no caso de demandas
            urgentes na região próxima ao Parque Ibirapuera e locais nobres e centrais de São Paulo, como:</p>


        <ul>
            <li>Pinheiros;</li>
            <li>Paraíso;</li>
            <li>Jardins;</li>
            <li>Moema;</li>
            <li>Faria Lima;</li>
            <li>Lapa;</li>
            <li>Vila Madalena;</li>
            <li>Morumbi;</li>
            <li>Bela Vista.</li>
        </ul>


        <p>Outros bairros também estão no escopo do delivery de gelo 24 horas e cidades da região metropolitana podem
            consultar a disponibilidade de entrega de gelo.</p>


        <p>A Distribuidora de Gelo oferece esse serviço pioneiro de distribuição de gelo no município de São Paulo para
            atender tanto consumidores quanto empresas.</p>


        <p>Com isso, a fornecedora de gelo delivery atende demandas diversas, como:</p>


        <ul>
            <li>eventos particulares, como aniversários, confraternizações, churrascos etc.;</li>
            <li>eventos empresariais, como feiras, palestras, workshops etc.;</li>
            <li>empresas de atendimento ao cliente, como restaurantes, bares, casas noturnas, mercados, lanchonetes,
                food trucks, buffets, hospitais e outras.
            </li>
        </ul>


        <p>Essa ampliação e agilidade no fornecimento de gelo é um serviço pioneiro oferecido pela Distribuidora de Gelo
            para garantir mais facilidade e conforto para pessoas que precisam de gelo na organização de eventos
            diversos.&nbsp;</p>


        <h2><strong>Tipos de distribuição de gelo</strong></h2>


        <p>Para melhorar o atendimento ao cliente, a Distribuidora de Gelo disponibiliza sacos de gelo 5 kg e saco de
            gelo 10 kg, com gelo britado, o que garante que o cliente tenha a opção que mais atende às necessidades
            dele.</p>


        <p>Além da diversidade de produtos entregues, a empresa de distribuição de gelo ainda diversifica o tipo de
            delivery de gelo, podendo atender:</p>


        <ul>
            <li>entrega de gelo programada: serviço direcionado a pessoas físicas e jurídicas que, planejando um evento
                que demande o uso de gelo, podem agendar previamente a entre no local, data e horário desejados,
                garantindo previamente a disponibilidade de gelo no evento sem necessidade de deslocamento para buscá-lo
                e desperdício do produto no percurso;
            </li>
            <li>entrega de gelo emergencial: serviço inovador da Distribuidora de Gelo para que os clientes solicitem a
                entrega emergencial do gelo em caso de falta durante eventos, garantindo a continuidade da festa sem
                comprometer a conservação e resfriamento de alimentos e bebidas.
            </li>
        </ul>


        <p>Em uma cidade agitada e com deslocamento oneroso como São Paulo, um serviço de delivery de gelo 24 horas
            garante praticidade ao cliente, mas também menos tempo no trânsito, economia e comodidade.</p>


        <h2><strong>Qualidade do gelo na Distribuidora de Gelo&nbsp;</strong></h2>


        <p>Ao fornecer esse tipo diferenciado de entrega de gelo em São Paulo, com opções de gelo 5 kg e gelo 10 kg, e
            solicitações antecipadas ou emergenciais, a Distribuidora de Gelo espera atender com excelência e eficiência
            os moradores das regiões centrais e nobres de São Paulo.</p>


        <p>Esse comprometimento da marca também se reflete na qualidade do produto fornecido. O saco de gelo 5 kg e o
            saco de gelo 10 kg, britados, são produzidos com água potável, o que garante diversificação no uso do
            gelo.</p>


        <p>Além disso, a Distribuidora de Gelo atua exclusivamente com gelos certificados e que atendam os padrões de
            qualidade estipulados pelo Controle Hídrico do Estado de São Paulo (Cohesp).</p>


        <p>Com entrega de gelo 24 horas em diferentes regiões de São Paulo, a Distribuidora de gelo garante uma entrega
            rápida e dentro dos padrões de qualidade exigidos.</p>


        <p>Para saber mais entre em contato com nossa equipe!</p>

        <Link to="/">Inicio</Link>
    </Layout>
)

export default SecondPage
